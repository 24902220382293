import React from "react";
import { SocialIconBtn } from "./socialIconBtn";
import GoogleIcon from "./googleIcon";
import { GoogleKeys } from "../config";
export const GoogleLoginButton = () => {
  const handleLogin = () => {
    const clientId = GoogleKeys.googleClientId;
    const redirectUri = GoogleKeys.googleRedirectUri;
    const scope = GoogleKeys.googleScope;
    const responseType = "code"; // Authorization code grant type
    const accessType = "offline"; // Request refresh token
    const prompt = "consent"; // Force consent screen
    localStorage.setItem("trueToken", true);
    // Construct the OAuth 2.0 URL
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;
    window.location.href = authUrl;
  };

  return (
    <>
      <SocialIconBtn icon={GoogleIcon} label="Sign up with Google" backgroundColor="#006BFF" borderColor="#006BFF" googleLogin={handleLogin} />
    </>
  );
};
