import React, { useState, useEffect } from "react";
import { Grid, Container, Button, Link, TextField } from "@mui/material";
import { Header } from "../../components/layout_components/header";
import Check from "../../assets/check.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { GoogleKeys } from "../../config";
// import MicroSoftIcon from "../../components/microSoftIcon";
import { GoogleLoginButton } from "../../components/googleLogin";
import Footer from "../../components/Footer";

export const SignUp = () => {
  const location = useLocation();
  const [error, setError] = useState("");
  const [showEmail, setShowEmail] = useState("");
  const navigate = useNavigate();

  // Validate email format
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSignUpLogin = async (e) => {
    e.preventDefault();

    if (showEmail.trim() === "") {
      setError("Please enter your email.");
    } else if (!validateEmail(showEmail)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
      const clientId = GoogleKeys.googleClientId;
      const redirectUri = GoogleKeys.googleRedirectUri;
      const scope = GoogleKeys.googleScope;
      const responseType = "code"; // Authorization code grant type
      const accessType = "offline"; // Request refresh token
      const prompt = "consent";
      const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&prompt=${prompt}`;

      // Redirect to Google's OAuth 2.0 authorization server
      window.location.href = authUrl;
    }
  };
  useEffect(() => {
    const email = localStorage.getItem("email");
    const params = new URLSearchParams(location.search);
    const clearEmail = params.get("clearEmail") === "true";

    if (email && !clearEmail) {
      setShowEmail(email);
    } else {
      setShowEmail("");
    }
  }, [location]);

  return (
    <>
      <Grid sx={{ height: "15vh" }}>
        <Header />
      </Grid>
      <Grid sx={{ height: "85vh" }} container justifyContent={"center"} alignItems={"center"}>
        <Container>
          <Grid container justifyContent={"space-between"} alignItems={"center"} pt={5}>
            <Grid item md={5}>
              <Grid component={"h1"} className="WelcomeHeading1">
                <Grid component={"span"} className="">
                  Create your
                </Grid>
                <Grid component={"span"} className="WelcomeHeading1Span">
                  {" "}
                  free{" "}
                </Grid>
                <Grid component={"span"} className="">
                  account
                </Grid>
              </Grid>
              <Grid className="welcomeTextBlock">
                <Grid component={"p"} className="welcomePara">
                  Easily schedule with clients and recruits with a free Krifly
                  account.
                </Grid>
                <Grid>
                  <Grid component="p" className="accountDescription">
                    This Teams trial includes upgrades like:
                  </Grid>
                  <Grid>
                    <Grid container spacing={2} mb={2}>
                      <Grid item md={1} className="imgCheck">
                        <img src={Check} alt="check" />
                      </Grid>
                      <Grid item md={11} className="checkText">
                        Ability to book meetings as a team with clients and more
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                      <Grid item md={1}>
                        <img src={Check} alt="check" />
                      </Grid>
                      <Grid item md={11} className="checkText">
                        Unlimited event types (30-minute video call, 15-minute
                        phone call)
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                      <Grid item md={1}>
                        <img src={Check} alt="check" />
                      </Grid>
                      <Grid item md={11} className="checkText">
                        Remove Krifly branding
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} mb={2}>
                      <Grid item md={1}>
                        <img src={Check} alt="check" />
                      </Grid>
                      <Grid item md={11} className="checkText">
                        Ability to charge for meetings with PayPal and Stripe
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid className="signUpForm">
                <Grid className="signUpText">
                  Sign up for your Krifly account
                </Grid>
                <Grid component={"p"} className="signupDes">
                  Always free! No credit card required.
                </Grid>
                <Grid component={"form"} onSubmit={handleSignUpLogin}>
                  <TextField
                    placeholder="Enter your email"
                    className="inputField"
                    value={showEmail}
                    onChange={(e) => setShowEmail(e.target.value)}
                    autoComplete="email"
                  />
                  <Grid component={"span"} className="errorMsg">
                    {error}
                  </Grid>
                  <Grid mt={3}>
                    <Button className="btn signUpBtn" type="submit">
                      Sign up
                    </Button>
                  </Grid>
                </Grid>
                <Grid className="orDiv">
                  <Grid component={"p"} className="orText">
                    OR
                  </Grid>
                </Grid>
                <Grid className="signUpPara">
                  Easily connect your krifly by signing up with your Google
                  account.
                </Grid>
                <Grid className="socialIcons">
                  <GoogleLoginButton />
                </Grid>
                <Grid item className="loginText">
                  <Link
                    underline="none"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log in
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </Grid>
    </>
  );
};
export default SignUp;
