  import React, { useState, useEffect } from "react";
  import { Grid, TextField, InputAdornment, Button, Typography, Card,
    CardContent,
    Checkbox,
    Link,
    IconButton,
  } from "@mui/material";
  import SearchIcon from "@mui/icons-material/Search";
  import AddIcon from "@mui/icons-material/Add";
  import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
  import SettingsIcon from "@mui/icons-material/Settings";
  import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
  import ContentCopyIcon from "@mui/icons-material/ContentCopy";
  import { useNavigate } from "react-router-dom";
  import { AuthServices } from "../services/authServices";
  import { ManagementServices } from "../services/managementServices";
  import DeleteModal from "../components/deleteModal";
  import { SuccessAlert, ErrorAlert } from "../components/alert";
  import CloseIcon from "@mui/icons-material/Close";
  import DoneIcon from "@mui/icons-material/Done";
  import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
  import { CustomPopover } from "../components/popover";
  import CopyButton from "../components/copyButton";
  import { formatDuration } from "../utils/timeFormate";
  import { BackdropWithLottie } from "../components/lottie";
  import { useAuth } from "../components/authContext";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 350,
    bgcolor: "background.paper",
    boxShadow: " rgba(100, 100, 111, 0.2)",
    outline: 0,
    p: 4,
    borderRadius: "10px",
  };
  export const Events = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [user, setUser] = useState({});
    const [events, setEvents] = useState([]);
    const [eventId, setEventId] = useState("");
    const [firstLetter, setFirstLetter] = useState("");
    const [anchorElEdit, setAnchorElEdit] = useState(null);
    const [anchorElCopy, setAnchorElCopy] = useState(null);
    const [copyText, setCopyText] = useState("Copy Link");
    const open = Boolean();
    const id = open ? "simple-popover" : undefined;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [alrtMsg, setAlrtMsg] = useState("");
    const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
    const [errorAlrtOpen, setErrorAlrtOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [copiedEventId, setCopiedEventId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [eventUniqueId, setEventUniqueId] = useState("");
    const [eventIds, setEventIds] = useState([]);
    const [openLottie, setOpenLottie] = useState(false);
    const handleCloseDeleteModal = () => setOpenDeleteModal(false);
    const handleCloseLottie = () => {
      setOpenLottie(false);
    };
    const closeSuccess = () => {
      setSuccessAlrtOpen(false);
    };
    const closeError = () => {
      setErrorAlrtOpen(false);
    };
    

    const handleOpenDeleteModal = () => {
      setOpenDeleteModal(true);
      setAnchorElEdit(null);
    };

    const handleClose = () => {
      setAnchorElEdit(null);
      setAnchorElCopy(null);
    };

    const handleButtonClick = () => {
      navigate("/app/eventType");
    };
    const handleLinkClick = () => {
      if (user?.myLinkName) {
        navigate(`/${user.myLinkName}`, {
          state: { linkName: user.myLinkName },
        });
      }
    };
  
    const handleClick = (event, id, uniqueId,name) => {
      setEventIds(id);
      setEventUniqueId(uniqueId);
      event.stopPropagation();
      setAnchorElEdit(event.currentTarget);
      setEventId(id); // Set eventId here
    };

    const handleAuthorizationCode = async (authorizationCode) => {
      try {
        const response = await AuthServices.fetchAccessToken(authorizationCode);
        if (response.status === 200) {
          const { access_token } = response.data;
          fetchUserData(access_token);
          navigate(`/home?token=${access_token}`);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserData = async (accessToken) => {
      setOpenLottie(true);
      try {
        const response = await fetch('https://krifly.com/api/googleapis/getUserData', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // Specify that you're sending JSON
          },
          body: JSON.stringify({ token: accessToken }), // Send the token in the request body
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const data = await response.json(); // Parse the JSON response
        console.log(data);
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('trueToken', accessToken);
        login();
        setOpenLottie(false);
      } catch (error) {
        console.error(error);
        setOpenLottie(false); // Ensure loading animation is stopped on error
      }
    };
    

    const getFirstLetter = (name) => {
      if (name) {
        const firstName = name.split(" ")[0];
        return firstName.charAt(0);
      }
      return "";
    };

    const gettingAllEvents = async (res) => {
      setOpenLottie(true);
      try {
        const payload = {
          googleId: res?.googleId,
          userId: res?.id,
        };
        const response = await ManagementServices.AllEvents(payload);
        if (response.status === 1) {
          setOpenLottie(false);
          setEvents(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getSession = async () => {
      const storedUser = localStorage && localStorage?.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        setFirstLetter(getFirstLetter(parsedUser?.name));
        await gettingAllEvents(parsedUser);
        setFirstLetter(getFirstLetter(parsedUser?.name));
      } else {
        console.log("No user found in localStorage");
      }
    };

    const handleCheckboxChange = (event, eventId) => {
      if (event.target.checked) {
        setSelectedItems((prevSelected) => [...prevSelected, eventId]);
        setEventIds((prevSelected) => [...prevSelected, eventId]);
      } else {
        setSelectedItems((prevSelected) =>
          prevSelected.filter((id) => id !== eventId)
        );
      }
    };

    const handleSlotClick = (event, id) => {
      // const shortEventId = `${id.substring(0, 4)}-${id.substring(id.length - 4)}`;
      event.stopPropagation();
      setEventId(id);
      if (user?.myLinkName) {
        navigate(`/${user.myLinkName}/${id}`,{state: {

          linkName: user.myLinkName,
          uniqueId: id
        }});
      }
    };

    const handleEventClick = (event) => {
      setEventId(id);
      navigate(`/editEvent/${user.myLinkName}/${event.name}`);
    };
    const editEvent = () => {
      navigate(`/editEvent/${user.myLinkName}/${eventUniqueId}`);
    };
    const editPage = () => {
      navigate("/app/linkPage");
    };

    const handleCopyLink = () => {
      const linkToCopy = `${window.location.origin}/${user?.myLinkName}`;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(linkToCopy)
          .then(() => {
            setCopyText("Copied"); // Show 'Link Copied'
            setTimeout(() => {
              setCopyText("Copy Link"); // Revert to 'Copy' after 2 seconds
            }, 2000);
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        // Fallback method for copying
        const textArea = document.createElement("textarea");
        textArea.value = linkToCopy;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          const successful = document.execCommand("copy");
          if (successful) {
            setCopyText("Copied"); // Show 'Link Copied'
            setTimeout(() => {
              setCopyText("Copy Link"); // Revert to 'Copy' after 2 seconds
            }, 2000);
          } else {
            console.error("Fallback: Oops, unable to copy");
          }
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }

        document.body.removeChild(textArea);
      }
    };

    const handleSearchEvent = (event) => {
      setSearchTerm(event.target.value);
    };
    const filteredEvents = events.filter(
      (event) =>
        event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        event.durationWithFormat.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const popoverItemsEdit = [
      {
        icon: ModeEditOutlineOutlinedIcon,
        text: "Edit",
        onClick: editEvent,
      },
      {
        icon: DeleteOutlinedIcon,
        text: "Delete",
        onClick: handleOpenDeleteModal,
      },
    ];

    const AllEvents = filteredEvents.map((event) => {
      const formattedDuration = formatDuration(event?.durationWithFormat);
      return (
        <Grid
          item
          className="eventCard"
          md={3.8}
          key={event.id}
          onClick={(e) => handleEventClick(event)}
        >
          <Card
            className="cardBlock"
            px={3}
            sx={{ borderTop: `6px solid ${event.colorCode} !important` }}
          >
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              p={2}
            >
              <Grid item>
                <Checkbox
                  {...{ inputProps: { "aria-label": "Checkbox demo" } }}
                  onChange={(e) => handleCheckboxChange(e, event.id)}
                  checked={selectedItems.includes(event.id)}
                  onClick={(e) => e.stopPropagation()}
                />
              </Grid>
              <Grid
                item
                sx={{ cursor: "pointer" }}
                onClick={(e) => handleClick(e, event.id, event.name)}
              >
                <Grid
                  container
                  alignItems={"center"}
                  aria-describedby={id}
                  sx={{ cursor: "pointer" }}
                >
                  <SettingsIcon className="settingsIcon" />
                  <ArrowDropDownOutlinedIcon />
                </Grid>
              </Grid>
            </Grid>
            <CardContent className="cardContent">
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="meetingText"
              >
                {event?.name}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: "16px" }}
              >
                {formattedDuration}, One-on-One
              </Typography>
              <Link
                className="linkPage"
                onClick={(e) => handleSlotClick(e, event.name)}
              >
                <Typography variant="body2">View booking page</Typography>
              </Link>
            </CardContent>
            <Grid container justifyContent={"space-between"} p={2}>
              <CopyButton
                dynamicContent={`${window.location.origin}/${user.myLinkName}/${event.name}`}
                id={event.uniqueId}
                className="copyText"
                onCopy={(eventId) => setCopiedEventId(eventId)}
              >
                {copiedEventId === event.uniqueId ? (
                  <Typography className="copiedText">
                    {" "}
                    <DoneIcon />
                    Copied
                  </Typography>
                ) : (
                  <Typography className="copyText">
                    {" "}
                    <ContentCopyIcon />
                    Copy link
                  </Typography>
                )}
              </CopyButton>
             
            </Grid>
          </Card>
        </Grid>
      );
    });

    const deleteEvent = async () => {
      console.log(eventIds);
      const payload = {
        ids: eventIds,
      };
      try {
        const response = await ManagementServices.DeleteEvent(payload);
        if (response.status === 1) {
          setOpenDeleteModal(false);
          setSuccessAlrtOpen(true);
          setAlrtMsg(response.message);
          setSelectedItems([]);
          getSession();
        } else {
          setErrorAlrtOpen(true);
          setAlrtMsg(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      const initialize = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
        if (code) {
          await handleAuthorizationCode(code); // Wait for handleAuthorizationCode to complete
        }
        const accessToken = queryParams.get("token");
        if (accessToken) {
          await fetchUserData(accessToken);
          const urlWithoutToken =
            window.location.pathname +
            window.location.search.replace(/([&?]token=[^&]*)/, "");
          window.history.replaceState(null, "", urlWithoutToken);
        }
        await getSession(); // Wait for getSession to complete
      };

      initialize();
    }, []);

    return (
      <>
        <Grid sx={{ height: "100%" }}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ height: "15%" }}
          >
            <Grid item component={"h1"} className="pageTitle">
              Event Types
            </Grid>
            <Grid item>
              <TextField
                className="searchTextField"
                size="small"
                variant="outlined"
                placeholder="Find event types"
                value={searchTerm}
                onChange={(e) => {
                  handleSearchEvent(e);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className="profileHeaderBlock"
            sx={{ height: "15%" }}
          >
            <Grid item>
              <Grid container alignItems="center" gap={2}>
                <Grid item>
                  <Grid
                    container
                    alignItems="center"
                    className="profileLetter profilePictureBlock"
                  >
                    {user && user.profilePic ? (
                      <img src={user.profilePic} alt="profile" />
                    ) : (
                      <Grid item component="span">
                        {firstLetter}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <Grid item>
                      <Typography className="profileText">
                        {user?.name}
                      </Typography>
                      <Grid container alignItems={"center"} gap={3}>
                        <Grid item>
                          <Typography
                            className="profileLink"
                            onClick={handleLinkClick}
                          >
                            https://krifly.com/{user?.myLinkName}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            onClick={handleCopyLink}
                            className="iconButonSvg"
                          >
                            {copyText === "Copied" ? (
                              <DoneIcon />
                            ) : (
                              <ContentCopyIcon />
                            )}
                          </IconButton>
                          <IconButton onClick={editPage}>
                            <ModeEditOutlineOutlinedIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" gap={1}>
                <Button
                  className="btn createEventBtn"
                  onClick={handleButtonClick}
                >
                  <AddIcon className="addIcon" />
                  New Event Type
                </Button>
               
              </Grid>
            </Grid>
          </Grid>
          <Grid sx={{ height: "70%", overflow: "auto" }}>
            <Grid container gap={2.9} py={5}>
              {AllEvents.length === 0 ? (
                <Grid item xs={12}>
                  <Typography
                    component={"h1"}
                    className="pageTitle"
                    sx={{ textAlign: "center", fontSize: "20px" }}
                  >
                    No events available
                  </Typography>
                </Grid>
              ) : (
                AllEvents
              )}
            </Grid>
          </Grid>
        </Grid>

        <CustomPopover id="popoverId1" open={Boolean(anchorElEdit)} anchorEl={anchorElEdit} handleClose={handleClose} items={popoverItemsEdit} />

        <DeleteModal open={openDeleteModal} handleClose={handleCloseDeleteModal} handleLogout={deleteEvent} text="Do you want to delete this event?" />

        <SuccessAlert alertMsg={alrtMsg} open={successAlrtOpen} close={closeSuccess} />
        <ErrorAlert alertMsg={alrtMsg} open={errorAlrtOpen} close={closeError} />
        <BackdropWithLottie open={openLottie} onClose={handleCloseLottie} />
        <Grid>
          {selectedItems.length > 0 && (
            <Typography variant="body1" sx={{ position: "fixed", bottom: 20, left: "50%", transform: "translateX(-50%)", background: "#fff", padding: "10px 20px", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              {selectedItems.length} items selected
              <IconButton size="small" onClick={() => setSelectedItems([])} sx={{ marginLeft: 2 }}>
                <CloseIcon />
              </IconButton>
              <IconButton size="small" onClick={handleOpenDeleteModal} sx={{ marginLeft: 2 }}>
                <DeleteOutlinedIcon />
              </IconButton>
            </Typography>
          )}
        </Grid>
      </>
    );
  };
