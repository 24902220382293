import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Events } from '../pages/events';
import { Layout } from '../pages/layout';
import { EventType } from '../pages/eventType';
import { CreateEvent } from '../pages/createEvent';
import { LinkDetails } from '../pages/linkDetails';
import { EventSlot } from '../pages/eventSlot';
import { EventDetails } from '../pages/eventDetails';
import { EventScheduled } from '../pages/eventScheduled';
import { Profile } from '../pages/profile';
import { LinkPage } from '../pages/link';
import { ScheduledEvents } from '../pages/scheduledEvents';
import { EditEvent } from '../pages/editEvent';
import { ProtectedRoute } from './ProtectedRoute';
import { PageNotFound } from '../pages/notFound';
import Availability from '../pages/Availability';
import Branding from '../pages/Branding';

export const IndividualRoutes = () => {
  return (
    <Routes>
      <Route path="/app" element={<ProtectedRoute component={Layout} />}>
        <Route index element={<ProtectedRoute component={Events} />} />
        <Route path="events" element={<ProtectedRoute component={Events} />} />
        <Route path="availability" element={<ProtectedRoute component={Availability} />} />
        <Route path="branding" element={<ProtectedRoute component={Branding } />} />
        <Route path="scheduledEvents" element={<ProtectedRoute component={ScheduledEvents} />} />
        <Route path="eventType" element={<ProtectedRoute component={EventType} />} />
        <Route path="profile" element={<ProtectedRoute component={Profile} />} />
        <Route path="linkPage" element={<ProtectedRoute component={LinkPage} />} />
      </Route>
      <Route path="createEvent" element={<CreateEvent />} />
      <Route path="/:myLinkName" element={<LinkDetails />} />
      <Route path="/:myLinkName/:eventId" element={<EventSlot />} />
      <Route path="eventDetails" element={<EventDetails />} />
      <Route path="eventScheduled" element={<EventScheduled />} />
      <Route path="/editEvent/:myLinkName/:eventId" element={<EditEvent />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};
