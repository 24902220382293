import React, { useState, useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import {
  Grid,
  Typography,
  Button,
  Divider,
  Checkbox,
  IconButton,
  Box,
  Select,
  MenuItem,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Autocomplete,
  TextField,
} from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { ManagementServices } from "../services/managementServices";
import moment from "moment-timezone";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "notistack";
import { SuccessAlert } from "../components/alert";

const generateTimeOptions = () => {
  const times = [];
  for (let i = 0; i < 24 * 4; i++) {
    const hour = Math.floor(i / 4);
    const minute = (i % 4) * 15;
    const time = dayjs().hour(hour).minute(minute);
    times.push({ value: time.format("HH:mm"), label: time.format("h:mm A") });
  }
  return times;
};

const timeOptions = generateTimeOptions();
const Availability = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [copySourceDay, setCopySourceDay] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  // const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [error, setError] = useState("");
  const [successAlrtOpen, setSuccessAlrtOpen] = useState(false);
  const [alrtMsg, setAlrtMsg] = useState("");
  const [timeSlots, setTimeSlots] = useState({});
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({
    Sun: true,
    Mon: true,
    Tue: true,
    Wed: true,
    Thu: true,
    Fri: true,
    Sat: true,
  });
  const [loading, setLoading] = useState(false);
  const [initialFetch, setInitialFetch] = useState(true);
  const closeSuccess = () => {
    setSuccessAlrtOpen(false);
  };
  const fetchAvailabilitySlots = async () => {
    setLoading(true);
    const userId = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).id
      : null;

    try {
      const payload = { userId: userId };
      const response = await ManagementServices.availabilityList(payload);
      
      if (response.status === 1 && response.data.availabilityData.length > 0) {
        // Set the timezone
        setSelectedTimeZone(response.data.timezone);

        const fetchedSlots = response.data.availabilityData.reduce(
          (acc, { wday, intervals }) => {
            const dayName = wday.charAt(0).toUpperCase() + wday.slice(1);
            acc[dayName] = intervals.map(({ from, to }) => ({
              start: moment(from, "HH:mm:ss").format("HH:mm"),
              end: moment(to, "HH:mm:ss").format("HH:mm"),
            }));
            return acc;
          },
          {
            Sun: [],
            Mon: [],
            Tue: [],
            Wed: [],
            Thu: [],
            Fri: [],
            Sat: [],
          }
        );
        setTimeSlots(fetchedSlots);
      } else {
        await insertAvailability(userId); // Call insertAvailability if no slots found
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setInitialFetch(false);
    }
  };

  const handleUpdateTimeZone = async () => {
    const userId = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).id
      : null;

    try {
      const payload = { userId, timezone: selectedTimeZone };
      const response = await ManagementServices.updateTimeZone(payload); // Implement this API call in your service
      if (response.status === 1) {
        setUpdateSuccess(true);
        // Optionally, you can refetch availability slots to get the latest data
        fetchAvailabilitySlots();
      } else {
        setUpdateSuccess(false);
      }
    } catch (error) {
      console.error(error);
      setUpdateSuccess(false);
    }
  };

  useEffect(() => {
    fetchAvailabilitySlots();
  }, []);
  // Utility function to check for overlapping time slots
  const hasOverlap = (slots) => {
    for (let i = 0; i < slots.length; i++) {
      for (let j = i + 1; j < slots.length; j++) {
        if (
          (slots[i].start >= slots[j].start && slots[i].start < slots[j].end) ||
          (slots[j].start >= slots[i].start && slots[j].start < slots[i].end)
        ) {
          return true; // Overlap found
        }
      }
    }
    return false;
  };

  const handleCheckboxChange = (day) => {
    setCheckboxStates((prev) => ({ ...prev, [day]: !prev[day] }));
    if (checkboxStates[day]) {
      setTimeSlots((prev) => ({ ...prev, [day]: [] }));
    }
  };
  // Helper function to check for time overlap
  const checkTimeOverlap = (day, newSlot) => {
    const slots = timeSlots[day];
    return slots.some((slot) => {
      return (
        (newSlot.start >= slot.start && newSlot.start < slot.end) || // Overlaps start
        (newSlot.end > slot.start && newSlot.end <= slot.end) || // Overlaps end
        (newSlot.start < slot.start && newSlot.end > slot.end) // Encompasses slot
      );
    });
  };
  const insertAvailability = async (userId) => {
    try {
      const defaultSlots = {
        Sun: [{ from: "09:00", to: "17:00" }],
        Mon: [{ from: "09:00", to: "17:00" }],
        Tue: [{ from: "09:00", to: "17:00" }],
        Wed: [{ from: "09:00", to: "17:00" }],
        Thu: [{ from: "09:00", to: "17:00" }],
        Fri: [{ from: "09:00", to: "17:00" }],
        Sat: [{ from: "09:00", to: "17:00" }],
      };

      const payload = {
        userId: userId,
        timezone: "UTC",
        availabilityData: Object.entries(defaultSlots).map(([day, slots]) => ({
          type: "wday",
          wday: day.toLowerCase(),
          intervals: slots.map((slot) => ({
            from: slot.from,
            to: slot.to,
          })),
        })),
      };

      const response = await ManagementServices.insertAvailability(payload);
      if (response.status === 1) {
        fetchAvailabilitySlots(); // Refresh the availability after insertion
      }
    } catch (error) {
      console.error("Error inserting availability:", error);
    }
  };

  const handleSaveAvailability = async () => {
    const userId = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).id
      : null;
    setLoading(true);
    const payload = {
      userId: userId,
      timezone: selectedTimeZone,
      availabilityData: Object.entries(timeSlots).map(([day, slots]) => ({
        type: "wday",
        wday: day.toLowerCase(),
        intervals: slots.map((slot) => ({
          from: slot.start,
          to: slot.end,
        })),
      })),
    };

    try {
      const response = await ManagementServices.editAvailability(payload);
      console.log("API Response:", response); // Log the response for debugging

      // Check for successful message
      if (response.message === "Availability updated successfully") {
        setSuccessAlrtOpen(true); // Trigger your alert/modal
        setAlrtMsg(response.message);
        fetchAvailabilitySlots();
      } else {
        alert(
          `Failed to save availability. Error: ${
            response.message || "Please try again."
          }`
        );
      }
    } catch (error) {
      console.error("Error saving availability:", error);
      alert("An error occurred while saving availability. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleTimeChange = (day, index, isStart, value) => {
    setTimeSlots((prevSlots) => {
      const updatedSlots = [...prevSlots[day]];
      if (isStart) {
        updatedSlots[index].start = value;
      } else {
        updatedSlots[index].end = value;
      }
      return {
        ...prevSlots,
        [day]: updatedSlots,
      };
    });
  };

  const handleRemoveSlot = (day, index) => {
    setTimeSlots((prevSlots) => ({
      ...prevSlots,
      [day]: prevSlots[day].filter((_, i) => i !== index),
    }));
  };

  const handleAddSlot = (day) => {
    const slotsForDay = timeSlots[day];
    const lastEndTime = slotsForDay.length > 0 ? slotsForDay[slotsForDay.length - 1].end : "09:00";
    const newSlot = { start: lastEndTime, end: incrementTime(lastEndTime, 1) };

    if (checkTimeOverlap(day, newSlot)) {
      const errorMessage = `Time overlaps with another slot on ${day}.`;
      console.log(errorMessage);
      enqueueSnackbar(errorMessage, { variant: "error" });
      setError(errorMessage);
    } else {
      setError("");
      setTimeSlots((prevState) => ({
        ...prevState,
        [day]: [...slotsForDay, newSlot],
      }));
    }
  };

  const incrementTime = (time, hoursToAdd) => {
    const [hours, minutes] = time.split(":").map(Number);
    const newHours = (hours + hoursToAdd) % 24; // Ensure hours wrap around
    return `${newHours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };

  const allTimeZones = moment.tz.names();

  useEffect(() => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
    setSelectedTimeZone(userTimeZone);
    if (initialFetch) {
      fetchAvailabilitySlots();
    }
  }, [initialFetch]); // Only run if initialFetch is true

  const handleOpenCopyDialog = (sourceDay) => {
    setCopySourceDay(sourceDay);
    setOpenDialog1(true); // Open dialog when CopyIcon is clicked
  };

  const handleCopySlots = (targetDay) => {
    if (copySourceDay && timeSlots[copySourceDay].length > 0) {
      setTimeSlots({
        ...timeSlots,
        [targetDay]: [...timeSlots[copySourceDay]], // Replace target day slots with source day slots
      });
    }
    setOpenDialog1(false); // Close dialog after copying
  };

  return (
    <Grid sx={{ height: "100%" }}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ height: "15%" }}>
        <Grid item component="h1" className="pageTitle">
          Availability
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" sx={{ height: "85%", mb: 4 }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
              <Grid mt={4} md={12}>
      <Typography variant="h6" fontWeight={700}>
        Time Zone
      </Typography>
      <Autocomplete
        value={selectedTimeZone}
        onChange={(event, newValue) => setSelectedTimeZone(newValue)}
        options={allTimeZones}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Time Zone"
            variant="outlined"
            sx={{ minWidth: 300, marginTop: 1 }}
          />
        )}
        sx={{ minWidth: 300, marginTop: 1 }}
      />
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleUpdateTimeZone}
        sx={{ marginTop: 2 }}
      >
        Save Time Zone
      </Button> */}
      {updateSuccess && (
        <Typography variant="body2" color="green" sx={{ marginTop: 1 }}>
          Time zone updated successfully!
        </Typography>
      )}
    </Grid>
              </Grid>

              <Divider />

              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ borderRight: "1px solid #ddd", pr: 2, mt: 4, mb: 2 }}>
                  <Typography variant="h6" fontWeight={700}>
                    Weekly Hours
                  </Typography>
                  <Grid mt={4}>
                    {Object.keys(timeSlots).map((day) => (
                      <Box key={day} mb={3}>
                        <Box display="flex" alignItems="center" mb={1}>
                          <Checkbox checked={checkboxStates[day]} onChange={() => handleCheckboxChange(day)} />
                          <Typography variant="h6" sx={{ flexGrow: 1, mr: 1, fontSize: "1rem", fontWeight: 700 }}>
                            {day}
                          </Typography>

                          {/* Display the first time slot in the same row */}
                          {timeSlots[day].length > 0 ? (
                            <>
                              <Select
                                value={timeSlots[day][0].start}
                                onChange={(event) =>
                                  handleTimeChange(
                                    day,
                                    0,
                                    true,
                                    event.target.value
                                  )
                                }
                                sx={{ marginRight: "8px", minWidth: "110px" }}
                              >
                                {timeOptions.map((time) => (
                                  <MenuItem key={time.value} value={time.value}>
                                    {time.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Typography>-</Typography>
                              <Select
                                value={timeSlots[day][0].end}
                                onChange={(event) =>
                                  handleTimeChange(
                                    day,
                                    0,
                                    false,
                                    event.target.value
                                  )
                                }
                                sx={{
                                  marginLeft: "8px",
                                  marginRight: "8px",
                                  minWidth: "110px",
                                }}
                              >
                                {timeOptions.map((time) => (
                                  <MenuItem key={time.value} value={time.value}>
                                    {time.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <IconButton
                                onClick={() => handleRemoveSlot(day, 0)}
                              >
                                <CloseIcon style={{ fontSize: "18px" }} />
                              </IconButton>
                            </>
                          ) : null}

                          <IconButton
                            onClick={() => handleAddSlot(day)}
                            aria-label="add time slot"
                          >
                            <AddIcon style={{ fontSize: "18px" }} />
                          </IconButton>
                          <IconButton onClick={() => handleOpenCopyDialog(day)}>
                            <ContentCopyIcon style={{ fontSize: "18px" }} />
                          </IconButton>
                        </Box>

                        {/* Display additional time slots */}
                        {timeSlots[day].length > 1 &&
                          timeSlots[day].slice(1).map((slot, index) => (
                            <Box
                              key={index + 1}
                              display="flex"
                              alignItems="center"
                              mb={1}
                            >
                              <Select
                                value={slot.start}
                                onChange={(event) =>
                                  handleTimeChange(
                                    day,
                                    index + 1,
                                    true,
                                    event.target.value
                                  )
                                }
                                sx={{ marginRight: "8px", minWidth: "120px" }}
                              >
                                {timeOptions.map((time) => (
                                  <MenuItem key={time.value} value={time.value}>
                                    {time.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Typography>-</Typography>
                              <Select
                                value={slot.end}
                                onChange={(event) =>
                                  handleTimeChange(
                                    day,
                                    index + 1,
                                    false,
                                    event.target.value
                                  )
                                }
                                sx={{
                                  marginLeft: "8px",
                                  marginRight: "8px",
                                  minWidth: "120px",
                                }}
                              >
                                {timeOptions.map((time) => (
                                  <MenuItem key={time.value} value={time.value}>
                                    {time.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              <IconButton
                                onClick={() => handleRemoveSlot(day, index + 1)}
                                aria-label="remove time slot"
                              >
                                <CloseIcon style={{ fontSize: "18px" }} />
                              </IconButton>
                            </Box>
                          ))}

                        {/* Error Message Section */}
                        {timeSlots[day].some(
                          (slot) => slot.end < slot.start
                        ) && (
                          <Typography color="error">
                            Choose an end time later than the start time.
                          </Typography>
                        )}
                        {timeSlots[day].some(
                          (slot) => !slot.start || !slot.end
                        ) && (
                          <Typography color="error">
                            Please select both start and end times.
                          </Typography>
                        )}

                        {/* Check for overlapping time slots */}
                        {hasOverlap(timeSlots[day]) && (
                          <Typography color="error">
                            Chosen time already exists, please select another.
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} sx={{ pl: 2, mt: 4, mb: 2 }}>
                  <Typography variant="h6" fontWeight={700}>
                    Date-Specific Hours
                  </Typography>
                  <Grid mt={4}>
                    <Typography variant="body1">
                      {" "}
                      Override your availability for specific dates when your
                      hours differ from your regular weekly hours.{" "}
                    </Typography>
                    <Button variant="contained" sx={{ mt: 2, background: "white", color: "black", border: "1px solid black" }} onClick={handleOpenDialog}>
                      + Add Specific Hours
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {error && <p style={{ color: "red" }}>{error}</p>}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} p={2}>
          <Button variant="contained" onClick={handleSaveAvailability} disabled={loading}>
            Save
          </Button>
        </Grid>
      </Grid>

      <SuccessAlert alertMsg={alrtMsg} open={successAlrtOpen} close={closeSuccess} />

      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="xs">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item md={12} sx={{ height: "100%" }}>
              <Typography px={4} className="eventName">
                Select the date
              </Typography>
              <Grid container sx={{ height: "100%" }} mt={2}>
                <Grid container item md={12} justifyContent="center" sx={{ height: "100%" }}>
                  <Calendar value={selectedDate} onChange={(date) => setSelectedDate(dayjs(date))} format="YYYY-MM-DD" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Dialog open={openDialog1} onClose={() => setOpenDialog1(false)}>
        <DialogTitle>Copy to Another Day</DialogTitle>
        <DialogContent>
          <Typography>Select a day to copy the time slots to:</Typography>
          <Select value="" onChange={(event) => handleCopySlots(event.target.value)} fullWidth>
            {Object.keys(timeSlots).map((day) => (
              <MenuItem key={day} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog1(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Availability;
