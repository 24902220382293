import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { AxiosClient } from '../axios/client';
import CloseIcon from '@mui/icons-material/Close';

const Branding = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [logo, setLogo] = useState(null); // State for the uploaded logo file
  const [error, setError] = useState(''); // State for error messages
  const [organizationName, setOrganizationName] = useState(''); // State for organization name
  const [loading, setLoading] = useState(false); // State for loading indicator
  const [existingLogo, setExistingLogo] = useState(null); // State for existing logo URL
  const [existingLogoFileName, setExistingLogoFileName] = useState(''); // State for existing logo file name

  const userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null;

  useEffect(() => {
    const fetchBrandDetails = async () => {
      if (!userId) return;

      try {
        const response = await AxiosClient.post('viewBranchDetails', { userId });
        if (response.data && response.data.status === 1) {
          setOrganizationName(response.data.data.organization_name);
          if (response.data.data.branding_logo) {
            const logoUrl = `${process.env.REACT_APP_IMAGES_URL}/brands/${response.data.data.branding_logo}`;
            setExistingLogo(logoUrl);
            setExistingLogoFileName(response.data.data.branding_logo);
          }
        } else {
          setError(response.data.message || 'Failed to fetch branding details.');
        }
      } catch (error) {
        console.error('API Error:', error);
      }
    };

    fetchBrandDetails();
  }, [userId]);

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (!validTypes.includes(file.type)) {
        setError('Please upload a JPG, PNG, or GIF image.');
        return;
      }
      if (file.size > maxSize) {
        setError('File size must be less than 5MB.');
        return;
      }
      setError('');
      setLogo(file); // Set the uploaded logo
      setExistingLogo(null); // Clear existing logo if a new one is uploaded
    }
  };

  const handleDeleteLogo = async () => {
    if (!existingLogoFileName) return;

    setLoading(true);
    setError('');

    try {
      const response = await AxiosClient.post('addBrandImage', {
        userId,
        organizationName,
        brandLogo: null,
        existingLogoFileName,
      });
      if (response.data.status === 1) {
        enqueueSnackbar('Logo deleted successfully!', { variant: 'success' });
        setExistingLogo(null);
        setExistingLogoFileName('');
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('An error occurred while deleting the logo. Please try again.');
      enqueueSnackbar('An error occurred while deleting the logo. Please try again.', { variant: 'error' });
      console.error('API Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveChanges = async () => {
    if (!userId) {
      setError('User ID not found. Please log in again.');
      return;
    }

    if (!organizationName || (!logo && !existingLogoFileName)) {
      setError('Please provide both organization name and logo.');
      return;
    }

    setLoading(true);
    setError('');

    const formData = new FormData();
    formData.append('userId', userId);
    formData.append('organizationName', organizationName);
    if (logo) {
      formData.append('brandLogo', logo);
    } else if (existingLogoFileName) {
      formData.append('brandLogo', existingLogoFileName);
    }

    try {
      const response = await AxiosClient.post('addBrandImage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response.data.status === 1) {
        enqueueSnackbar('Branding details updated successfully!', { variant: 'success' });
        
        // Set the new existing logo URL if it was returned
        if (response.data.data.branding_logo) {
          const updatedLogoUrl = `${process.env.REACT_APP_IMAGES_URL}/brands/${response.data.data.branding_logo}`;
          setExistingLogo(updatedLogoUrl);
          setExistingLogoFileName(response.data.data.branding_logo);
        }
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError('An error occurred while saving. Please try again.');
      enqueueSnackbar('An error occurred while saving. Please try again.', { variant: 'error' });
      console.error('API Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseLogo = () => {
    setLogo(null);
    setExistingLogo(null);
  };

  return (
    <Grid sx={{ height: '100%' }}>
      <Grid container justifyContent="space-between" alignItems="center" sx={{ height: '15%' }}>
        <Grid item component="h1" className="pageTitle">
          Branding
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" sx={{ height: '85%' }}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ pr: 2, mt: 2 }}>
              <Grid>
                <Grid item component={'h4'}> Organization Name </Grid>
                <Grid item component={'p'} mt={2} sx={{ lineHeight: '1.8rem' }}>
                  How would you like to be represented when users sign up for Krifly?
                </Grid>
                <TextField
                  label="Enter Organization Name"
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 2 }}
                  value={organizationName}
                  onChange={(e) => setOrganizationName(e.target.value)}
                />
                <Divider sx={{ mt: 4 }} />
                <Grid item component={'h4'} mt={4}>
                  Organization Logo
                </Grid>
                <Grid item component={'p'} mt={2} sx={{ lineHeight: '1.8rem' }}>
                  Set a logo for everyone in this organization. The logo appears at the top left corner of the scheduling page for all members of your group.
                </Grid>

                {existingLogo ? (
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item position="relative">
                      <img
                        src={existingLogo}
                        alt="Existing Logo"
                          style={{ width: '325px', height: '150px', objectFit: 'cover', marginBottom: '10px', textAlign: 'center', border: '1px solid black', padding: '1rem' }}
                      />
                      <CloseIcon
                        onClick={handleCloseLogo}
                        style={{ cursor: 'pointer', position: 'absolute', top: '0', right: '0', color: 'red', zIndex: 1 }}
                      />
                    </Grid>
                    <Grid container spacing={2} ml={2}>
                      <Grid item>
                        <Button variant="outlined" color="error" onClick={handleDeleteLogo}>
                          Delete Logo
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    {logo && (
                      <img
                        src={URL.createObjectURL(logo)}
                        alt="New Logo Preview"
                        style={{ width: '325px', height: '150px', objectFit: 'cover', marginBottom: '10px', textAlign: 'center', border: '1px solid black', padding: '1rem' }}
                      />
                    )}
                    <Button variant="contained" component="label" sx={{ mt: 2 }}>
                      Upload Logo
                      <input type="file" hidden accept="image/jpeg,image/png,image/gif" onChange={handleLogoUpload} />
                    </Button>
                  </>
                )}

                {error && (
                  <Typography color="error" sx={{ mt: 1 }}>
                    {error}
                  </Typography>
                )}

                <Divider sx={{ mt: 4 }} />

                <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                  <Button
                    variant="contained"
                    sx={{ mb: 2, mr: 2, ml: 4, backgroundColor: 'gray', p: 1.5, borderRadius: '10px' }}
                    onClick={handleSaveChanges}
                    disabled={loading}
                  >
                    {loading ? 'Saving...' : 'Save Changes'}
                  </Button>
                  <Button
                    onClick={() => {
                      setOrganizationName('');
                      setLogo(null);
                      setExistingLogo(null);
                      setError('');
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Branding;
